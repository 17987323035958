import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Content } from "../components/Content";
import { Section } from "../components/Section";
import { SectionHeading } from "../components/SectionHeading";

const Service: React.FC = ({ data }) => (
  <Layout>
    {data.allWordpressWpService.edges.map(
      ({ node: { id, title, content } }) => (
        <div key={id}>
          <Breadcrumbs title={title} />
          <Section>
            <SectionHeading>{title}</SectionHeading>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
          </Section>
        </div>
      )
    )}
  </Layout>
);

export const pageQuery = graphql`
  query($id: String) {
    allWordpressWpService(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          slug
          title
          content
        }
      }
    }
  }
`;

export default Service;
